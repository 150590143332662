<template>
<div></div>
</template>

<script>
  export default {
    mounted() {
      throw new Error("ERROR REPORTING WORKS")
    }
  }
</script>
